import type { MetaDescriptor } from "@remix-run/react";

export type SEOMetadata = {
  title: string;
  description: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogType?: string;
  ogUrl?: string;
  robotsFollowIndex?: boolean;
  canonical?: string;
};

export const SEOHead = ({
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  ogType,
  ogUrl,
  robotsFollowIndex = true,
  canonical,
}: SEOMetadata): MetaDescriptor[] => {
  return [
    { title },
    canonical && {
      tagName: "link",
      rel: "canonical",
      href: canonical,
    },

    {
      property: "og:title",
      content: ogTitle ?? title,
    },
    {
      name: "description",
      content: description,
    },
    {
      property: "og:description",
      content: ogDescription ?? description,
    },
    {
      property: "og:type",
      content: ogType ?? "website",
    },
    {
      property: "og:image",
      content: ogImage ?? `${ENV.PUBLIC_APP_URL}/og-img.png`,
    },
    {
      property: "og:image:url",
      content: ogImage ?? `${ENV.PUBLIC_APP_URL}/og-img.png`,
    },
    {
      property: "og:image:alt",
      content: title,
    },
    ogUrl && {
      property: "og:url",
      content: ogUrl,
    },
    robotsFollowIndex && {
      property: "robots",
      content: "index, follow",
    },
  ].filter(Boolean);
};
